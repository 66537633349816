import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { FaBars } from "react-icons/fa";
import { Container } from "reusecore/Layout";
import Box from "reusecore/Box";
import Text from "reusecore/Text";

import logowide from "assets/images/logo-africoin-wide.svg";

import Data from "./utility/data.js";
import ScrollspyMenu from "./utility/ScrollspyMenu";
import NavigationWrap from "./navigation.style";

const Navigation = () => {
  const [expand, setExpand] = useState(false); 

  useEffect(() => {
    const header = document.getElementById("navbar");

    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //[expand, scroll]
  return (
    <NavigationWrap
      id="navbar"
      className={`nav-block`}
    >
      <Container>
        <div className="navbar-wrap">
          <Link to="/" className="logo"> 
              <img src={logowide} alt="prime app landing" /> 
          </Link>
          <Box className="banner-btn">
            <Link to="https://t.me/africoin_one"
                className="btn btn-fill"
                target="_blank">
                {/* <FaGithub /> BUY  */}
                <Text as="span" style={{ paddingTop: 6, paddingLeft: 5, paddingRight: 5}}>Get in touch!</Text>
                {/* <Image src={logowide} alt="logo2" style={{ paddingRight: 25}}/> */}
            </Link>
          </Box>
          {/* <nav className="nav">
            <FaBars
              className="mobile-menu-icon"
              onClick={() => setExpand(!expand)}
            />
            <ScrollspyMenu
              className={`collapsed ${expand ? "is-expanded" : ""}`}
              menuItems={Data.menuItems}
            />
          </nav> */}
        </div>
      </Container>
    </NavigationWrap>
  );
};

export default Navigation;
